.tournament-table {
    .table-header {
        th {
            text-align: left;

            &:first-child {
                width: 10%;
            }

            &:last-child {
                width: 15%;
                text-align: left;
            }
        }
    }
}

.tournament-current-tr {
    .all-bets-tr-username {
        max-width: 50%;
        text-align: left;
    }

    td {
        &:first-child {
            width: 10%;
            text-align: left;
        }

        &:last-child {
            text-align: left;
        }
    }

    &:nth-child(1) {
        background-color: #867240;
    }

    &:nth-child(2) {
        background-color: #86878F;
    }

    &:nth-child(3) {
        background-color: #664E3A;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        .all-bets-tr-avatar {
            width: 30px;
            height: 30px;
        }
    }

    .table-share {
        display: inline-flex;
        margin-left: 1rem;
    }
}

.tc-tr-points {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tc-tr-points-image {
    width: 16px;
    height: 16px;
}

.tournament-tr-prize {
    font-family: $font-bold;
    color: white;
    font-size: 12px;

    @media (min-width: 1200px) {
        font-size: 14px;
    }
}

.tournament-tr-currency {
    font-family: $font-bold;
    font-size: 10px;
    color: white;
}

.tournament-current-tr-rank {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 100%;
    background-color: #252B63;
    font-family: $font-bold;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
    margin-right: 5px;
}

.tournament-current-tr {
    .tournament-rank-image {
        height: 22px;
        object-fit: cover;

        &.tournament-third {
            // margin-left: 5px;
        }
    }
}

.tournament-rank-image {
    height: 36px;
    object-fit: cover;

    &.tournament-third {
        // margin-left: 5px;
    }

    &.prize-4th-10th,
    &.prize-11th-20th,
    &.prize-21th-30th,
    &.prize-31th-40th,
    &.prize-41th-50th,
    &.prize-51th-60th {
        width: auto;
        height: 22px;
    }
}

.tournament-accordion-item {
    margin-bottom: 10px;
}

.tournament-accordion-header {
    background-color: #070a2a;
    border-radius: 5px;
    cursor: pointer;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.show {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media (min-width: 1024px) {
        background-color: #0E1340;
    }

}

.tournament-accordion-header-name {
    font-family: $font-bold;
    font-size: 14px;
    color: white;
}

.tournament-accordion-header-date {
    font-family: $font-regular;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
}

.tournament-prev-tr-username {
    // display: flex;
    // flex-direction: column;
}

.tournament-prev-tr-username-image {
    width: 16px;
}

.tournament-tr-flag {
    align-self: flex-start;
    padding-top: 3px;
}

.tournament-counter-holder {
    position: absolute;
    top: 3rem;
    right: 1rem;
    z-index: 2;
    color: white;
    cursor: pointer;

    &.hide {
        display: none;
    }

    &.no-header {
        @media (max-width: 1023px) {
            top: 0;
        }
    }

    &.tournament-in-progress {
        top: 5.5rem;

        &.no-header {
            @media (max-width: 1023px) {
                top: 1.75rem;
            }
        }

        @media (min-width: 1024px) {
            top: 10rem;
        }
    
        @media (max-height: 640px) and (min-width: 1024px) {
            top: 5.5rem;
        }

        @media (max-height: 800px) and (min-height: 640px)  and (min-width: 1024px) {
            top: 7.75rem;
        }
    }
}

.tournament-counter-header {
    position: relative;
}

.tournament-notif {
    position: absolute;
    top: 0;
    right: 0;
}

.tournament-counter-footer {
    display: flex;
    flex-direction: column;
    padding: .25rem;
    background: rgba(7, 10, 43, 0.9);
    box-shadow: 0px 3.09841px 7.74603px rgba(0, 0, 0, 0.25);
    border-radius: .25rem;
    color: white;
    font-family: $font-bold;
    font-size: 12px;
    margin-top: -18px;
    z-index: 1;
    position: relative;
    text-align: center;
}

.tournament-countdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $font-bold;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);

    &.red {
        color: #db1f35
    }
}

.tournament-info-holder {
    z-index: 3;
    top: 3rem;
    left: 0;
    right: 0;
    position: absolute;
    background: rgba(7, 10, 43, 0.9);
    box-shadow: 0px 3.09841px 7.74603px rgba(0, 0, 0, 0.25);
    border-radius: 0;

    @media (min-width: 1024px) {
        border-radius: 5px;
        left: 1rem;
        right: 1rem;
    }


    &.hide {
        display: none;
    }
}

.tournament-info-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-family: $font-bold;
    font-size: 14px;
    color: white;
    border-bottom: 1px solid rgba(69, 75, 122, .4);

    .bet-info-close {
        margin: 0;
    }
}

.tournament-info-prizes {
    max-height: 295px;
    overflow-y: auto;
}

.tournament-info-time,
.tournament-info-prizes,
.tournament-info-rules {
    padding: 10px;
    border-bottom: 1px solid rgba(69, 75, 122, .4);

    h5 {
        font-family: $font-bold;
        font-size: 12px;
        color: #64678D;
        text-transform: uppercase;
        margin-bottom: .5rem;
    }
}

.tournament-info-rules {
    border-bottom: none;

    p {
        font-family: $font-regular;
        font-size: 14px;
        color: rgba(255, 255, 255, .8);
        line-height: 1.3;
    }
}

.tournament-info-time-box {
    display: flex;
    align-items: center;
    border: 2px solid #3BC4F2;
    border-radius: .25rem;
    font-family: $font-semibold;
    font-size: 12px;
    color: white;

}

.titb-from-to {
    display: flex;
    flex: 1;
    align-items: center;

    .titb-from-to-text {
        font-family: $font-bold;
        font-size: 10px;
        color: #105F79;
        padding: 4px;
        background-color: #3BC4F2;
    }
}

.titb-image {
    margin: .25rem;
}

.tournament-info-prizes-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.25rem;
    margin-top: 2rem;
}

.tournament-info-prizes-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(99, 58, 250, 0.2);
    border: 1px solid #633AFA;
    padding: .5rem .25rem;
    border-radius: .25rem;

    h6 {
        color: #9E84FE;
        margin-bottom: .25rem;
    }

}

.tournament-info-prizes-image {
    position: absolute;
    top: -25px;
}

.tournament-info-prizes-col {
    width: 33.33%;
    padding: .25rem;

    &:nth-child(1) {
        .tournament-info-prizes-box {
            background: rgba(250, 169, 34, 0.2);
            border: 1px solid #FAA922;
            padding-top: 30px;

            h6 {
                color: #FAA922;
                text-transform: uppercase;
            }
        }

    }

    &:nth-child(2) {
        .tournament-info-prizes-box {
            background: rgba(173, 173, 173, 0.3);
            border: 1px solid #ADADAD;
            padding-top: 30px;

            h6 {
                color: #ADADAD;
                text-transform: uppercase;

            }
        }
    }

    &:nth-child(3) {
        .tournament-info-prizes-box {
            background: rgba(134, 91, 55, 0.4);
            border: 1px solid #865B37;
            padding-top: 30px;

            h6 {
                color: #865B37;
                text-transform: uppercase;

            }
        }
    }

    &.double {
        display: none;
    }
}

.tournament-info-prizes-amount {
    font-family: $font-bold;
    font-size: 14px;
    color: white;
}

.tournament-leaderboard-holder {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: rgba(7, 10, 42, .8);

    // .tournament-leaderboard-tr {
    //     &:last-child {
    //         border: 1px solid #db1f35;
    //     }
    // }

    &.no-header {
        @media (max-width: 1023px) {
            top: -20px;
        }
    }
}

.tournament-leaderboard {
    position: relative;
    padding: 0 1rem;
    background: linear-gradient(360deg, #070A2B -7.22%, rgba(7, 10, 43, 0) 22.78%);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    overflow: auto;
    height: 40px;
    max-height: 40px;

    @media (min-width: 1024px) {
        height: 100%;
        max-height: 112px;
    }

    @media (max-height: 640px) and (min-width: 1024px) {
        height: 100%;
        max-height: 40px;
    }
    @media (max-height: 800px) and (min-height: 640px)  and (min-width: 1024px) {
        height: 100%;
        max-height: 76px;
    }


    &.expanded {
        height: 100%;
        max-height: 60vh;
    }
}

.tournament-leaderboard-tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 16px;
    background-color: #202558;
    margin: .25rem 0;
    padding: .25rem;

    .all-bets-tr-username {
        color: white;
        font-family: $font-semibold;
        font-size: 14px;
        max-width: 95px;
    }

    .all-bets-tr-flag {
        margin-bottom: -3px;
    }

    .all-bets-tr-avatar {
        width: 24px;
        height: 24px;
    }
}

.tlt-position-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .25rem;

    .tournament-current-tr-rank {
        width: 26px;
        height: 26px;
    }

    .tournament-rank-image {
        height: 28px;
        width: 38px;
        object-fit: contain;
    }
}

.tournament-toggle {
    width: 38px;
    height: 20px;
    background-color: $blue-700;
    border-radius: 10px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -19px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    @include transition(0.6s ease-in-out all);

    svg {
        -webkit-transform: rotateX(180deg);
        transform: rotateX(180deg);
    }

    &.active {
        background-color: $yellow-200;

        svg {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);

            path {
                stroke: $blue-200;
            }
        }
    }
}

.tournament-leaderboard-tr-points {
    font-family: $font-bold;
    font-size: 12px;
    color: white;
    padding-right: 1rem;
    max-width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
        width: 20px;
        height: 20px;
    }
}

.canvas-tournament-active {
    canvas {
        top: 40px;
    }
}