.fullscreen-mode {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1110;
    background-color: $blue-400;
    color: white;
    &.hide {
        display: none;
    }

    h3 {
        text-align: center;
        padding: 0 .5rem;
        font-size: 20px;
    }

    .pop-up-close {
        width: 200px;
        margin-top: 1.5rem;
    }
}

.user-defined-username {
    z-index: 1109;
    background: rgba(7, 10, 42, 0.8);
}

.user-defined-username-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.user-defined-username-body {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #070a2a;
    border-radius: 1rem;
    z-index: 2;
  }