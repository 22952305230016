.provably-fair-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 180px;
  max-width: 300px;
  background-color: $blue-400;
  padding: 1.5rem;
  margin: 0 auto;
  border-radius: 10px;
  @include boxShadow(0px 0px 20px rgba(59, 196, 242, 0.15));
}

.pfi-image {
  margin-bottom: 1rem;
}

.pfi-title {
  font-family: $font-bold;
  color: white;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.pfi-description {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  line-height: 21px;
  text-align: center;
  opacity: 0.5;
  word-break: break-all;
}

.pfi-plus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pfi-arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}

.pfi-line {
  width: 2px;
  height: 20px;
  background-color: white;
  margin: 0.5rem 0;
  border-radius: 1px;
}

.plus-shape {
  width: 12px;
  height: 12px;
  position: relative;
}

.plus-shape:before,
.plus-shape:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: white;
}

.plus-shape:before {
  left: 50%;
  width: 2px;
  margin-left: -1px;
  height: 100%;
}

.plus-shape:after {
  top: 50%;
  height: 2px;
  margin-top: -1px;
  width: 100%;
}
