.desktop-dialog {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(3, 5, 32, 0.8);
  z-index: 3;
  padding: 1.5rem;

  &.active {
    display: block;
  }
}

.desktop-bet-info-holder {
  border-radius: 1rem;
  background-color: $blue-400;
  max-width: 465px;
  margin: 0 auto;
  margin-top: 2rem;
}

.desktop-bet-info {
  height: auto;

  @media (min-width: 1024px) {
    max-height: calc(100vh - 262px);
  }

  .simplebar-scrollbar {
    right: 4px;
    left: 0;
  }

  .simplebar-track.simplebar-vertical {
    top: -2px;
    bottom: 2px;
    right: -16px;
    width: 12px;
    border-radius: 6px;
  }

  .simplebar-scrollbar:before {
    background: #636479;
  }
}

.desktop-bet-info-text {
  font-family: $font-semibold;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 1.3;
  color: white;
  text-align: center;
}

.bet-verify-content-text {
  font-family: $font-regular;
  margin-bottom: 1rem;
  font-size: 14px;
  line-height: 1.3;
  color: white;
  text-align: left;
}

.raw-multiplier-holder{
  display: flex;
  align-items: center;
}

.bracket-open{
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  width: 10px; 
  height: 60px;
  margin-left: 10px;
}

.bracket-close{
  border-right: 1px solid white;
  border-bottom: 1px solid white; 
  width: 10px; 
  height: 60px;
  margin-right: 10px;
}

.inside-brackets{
  text-align: center;
}

.bracket-divider{
  border-top: 1px solid white; 
  width: 100;
}

.bracket-bottom-row{
  display: flex; 
  align-items: center;
}

.move-number-up{
  margin-top: -2px;
  margin-right: 10px;
}

.bet-verify-algo-table {
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: column;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
  }
}

.bet-verify-algo-holder {
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (min-width: 1024px) {
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (min-width: 1200px) {
    width: 70%;
    margin-bottom: 0;
  }
}

.bet-verify-algo-row {
  display: flex;
  font-family: $font-regular;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.3;
  color: white;
  width: 100%;
}

.bet-verify-algo-col {
  display: flex;
  align-items: center;

  &:first-child {
    justify-content: flex-end;
    padding-right: 8px;
    width: 70%;

  }
}

.bet-verify-algo-table-toggle {
  font-family: $font-regular;
  font-size: 12px;
  color: white;
  display: flex;
  margin-top: -15px;
  cursor: pointer;
  @include transition(0.3s ease-in-out all);

  &:hover {
    opacity: .8;
  }
}

.bet-verify-table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bvt-table {
  width: 110px;
  padding: 5px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 5px;

  span {
    width: 50%;
    font-family: $font-regular;
    font-size: 14px;
    color: white;
    padding: 1px 5px;
  }
}