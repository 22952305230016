$opacity:
	"10" 0.1,
	"20" 0.2,
	"30" 0.3,
	"40" 0.4,
	"50" 0.5,
	"60" 0.6,
	"70" 0.7,
	"80" 0.8,
	"90" 0.9,
	"100" 1;

@each $i in $opacity{
	.opacity-#{nth($i, 1)} {
		opacity: nth($i, 2);
	}
}

.duration-1 {
	@include animationDelay(0 !important);
}
.duration-2 {
	@include animationDelay(.05s !important);
}
.duration-3 {
	@include animationDelay(.1s !important);
}
.duration-4 {
	@include animationDelay(.15s !important);
}
.duration-5 {
	@include animationDelay(.2s !important);
}
.duration-6 {
	@include animationDelay(.25s !important);
}

.cursor-pointer {
	cursor: pointer;
}