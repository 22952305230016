.bottom-navigator {
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $blue-200;
  z-index: 1;

  @media (min-width: 1024px) {
    display: none;
    &.closeButton {
      display: flex;
      position: relative;
    }
  }
  a, a:hover, a:focus, a:active {
    text-decoration: none!important;
}
}

.bottom-navigator-link-chat {
  .pod-tabs-nav-badge {
    background-color: #ffbf19;
  }
}

.bottom-navigator-link {
  position: relative;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  // opacity: 0.3;

  text-decoration: none!important;
  //@include transition(0.3s ease-in-out all);

  &.r7css {
    font-size: 16px;
  }

  .bottom-navigator-link-opacity {
    display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.5;
      background-color: #030520;
  }

  .pod-tabs-nav-badge {
    position: absolute;
    z-index: 1;
    top: -10px;
    right: 50%;
    margin-right: -25px;
    color: #070a2b;
    font-family: $font-semibold;
  }

  .icon-inactive {
    display: block;
  }

  .icon-active {
    display: none;
  }

  &.active {
    opacity: 1;
    text-decoration: none!important;
    color: white;

    .icon-active {
      display: block;
    }

    .icon-inactive {
      display: none;
    }

    .bottom-navigator-link-opacity {
      display: none;
    }
  }
}
