.detailed-step {
  width: 290px;
  margin: 1rem auto;
  padding: 0;

  @media (min-width: 1024px) {
    margin: 2rem auto;
    padding: 0 1rem;
    width: 255px;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
  @media (min-width: 1400px) {
    padding: 0;
    width: 290px;
  }
}

.detailed-step-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.detailed-step-title {
  text-align: center;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.detailed-step-image {
  margin: 0 auto;
  width: 100%;
  padding-top: 75%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: contain;
  }
}

.detailed-step-text {
  padding: 0 1.25rem;
  text-align: center;
  color: white;
}

.malfunction-details {
  color: red;
  font-family: $font-bold;
  margin-bottom: 2rem;
  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
}

.detailed-rules-big-button {
  width: 180px !important;
}
