.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1111;
  background-color: $blue-400;
  &.hide {
    display: none;
  }
}

.loading-screen-footer {
  position: absolute;
  bottom: 2rem;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-top: 0.75rem;
  }
}

.loading-screen-text {
    font-family: $font-semibold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-top: 1rem;
    color: white;
}

.dot-pulse {
  position: relative;
  left: -9999px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: white;
  color: white;
  box-shadow: 9999px 0 0 -2px white;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
  margin-top: 14px;
  margin-left: 25px;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: white;
  color: white;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -2px white;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -2px white;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -2px white;
  }
  30% {
    box-shadow: 9984px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -2px white;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -2px white;
  }
  30% {
    box-shadow: 9999px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -2px white;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -2px white;
  }
  30% {
    box-shadow: 10014px 0 0 2px white;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -2px white;
  }
}
