.bets-table {
  width: 100%;
  table-layout: fixed;
}

// Previous Hand Table

.bets-table-total {
  .table-header {
    th {
      width: 20%;
      &:first-child {
        width: 45%;
        text-align: left;
        border-top-left-radius: 5px;
      }
      &:last-child {
      }
      &:nth-last-child(2) {
        text-align: center;
      }
      &:last-child {
        border-top-right-radius: 5px;
        width: 20%;
        text-align: right;

        &.share {
        width: 10%;
        }
      }
    }
  }
}

// My bets Table

.my-bets-table {
  margin-bottom: 1rem;
  .table-header {
    th {
      width: 25%;
      &:first-child {
        width: 25%;
        text-align: left;
        border-top-left-radius: 5px;
      }
      &:nth-last-child(2) {
        text-align: right;
      }
      &:last-child {
        border-top-right-radius: 5px;
        text-align: center;
      }
    }
  }
}

.bonusing-history-table {
  .table-header {
    th {
      width: 25%;
      &:nth-last-child(2) {
        text-align: center;
      }
    }
  }
}

// Table header

.table-header {
  height: 40px;
  background-color: $blue-1100;

  &.r7css {
    th {
      font-size: 14px;
    }
  }

  th {
    font-family: $font-bold;
    font-size: 12px;
    color: white;
    opacity: 0.5;
    text-align: center;
    width: 20%;
    padding: 0 0.25rem;

    &:first-child {
      width: 45%;
      text-align: left;
      border-top-left-radius: 5px;
      padding-left: 0.5rem;
    }
    &:last-child {
      text-align: right;
      border-top-right-radius: 5px;
      padding-right: 0.5rem;
    }
  }
}

.bet-wins-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $blue-300;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  padding-right: 15px;
  padding-left: 21px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 1024px) {
    background-color: $blue-400;
  }
}

.bet-wins-menu-left {
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
  opacity: 0.5;
  flex: 1;
  border-right: 1px solid $gray-100;
}

.bet-wins-menu-right {
  width: 80px;
  position: relative;

  .dropdown {
    .dropdown-toggle {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      border: none;
      font-family: $font-semibold;
      font-size: 14px;
      color: white;
      border-radius: 0;
      @include transition(0.3s ease-in-out all);

      &:focus {
        @include boxShadow(none !important);
      }

      &:hover {
        background-color: $blue-1200;
      }

      &:active {
        color: white;
        background-color: transparent;
        border-color: transparent;
        @include boxShadow(none !important);
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      width: 100%;
      min-width: fit-content !important;
      transform: none !important;
      background-color: $yellow-100;
      padding-top: 6px;
      margin-top: -1px !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      @include boxShadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    }

    .dropdown-item {
      padding: 0.25rem 0.75rem;
      font-family: $font-semibold;
      font-size: 14px;
      color: $blue-100;
      opacity: 0.7;

      &.active {
        opacity: 1;
        background-color: transparent;
      }
      &:hover {
        background-color: rgba(20, 25, 69, 0.1);
      }
    }
  }
}

.arrow-dropdown-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1111;

  &.rotate {
    @include transform(rotate(180deg));
  }
}

.load-more-button-holder {
  width: 150px;
  margin: 1rem auto 0 auto;
}
