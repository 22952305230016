.step {
  margin-bottom: 1rem;
}

.step-top {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-top-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 100%;

  &.step-1 {
    background-color: $step-1-color;
  }
  &.step-2 {
    background-color: $step-2-color;
  }
  &.step-3 {
    background-color: $step-3-color;
  }

  p {
    color: white;
    line-height: 1;
    font-family: $font-bold;
    font-size: 14px;
  }
}

.step-top-line {
  width: 2px;
  height: 8px;

  &.step-1 {
    background-color: $step-1-color;
  }
  &.step-2 {
    background-color: $step-2-color;
  }
  &.step-3 {
    background-color: $step-3-color;
  }
}

.step-bottom {
  position: relative;
  display: flex;
  height: 50px;
  border-radius: 25px;
}

.step-bottom-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  border-radius: 25px;

  &.step-1 {
    background: $step-1-gradient-color;
  }
  &.step-2 {
    background: $step-2-gradient-color;
  }
  &.step-3 {
    background: $step-3-gradient-color;
  }
}

.step-bottom-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 1;

  &.step-1 {
    background-color: $step-1-color;
  }
  &.step-2 {
    background-color: $step-2-color;
  }
  &.step-3 {
    background-color: $step-3-color;
  }
}

.step-bottom-text {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 0.5rem;
  padding-right: 1rem;
  font-family: $font-semibold;
  font-size: 14px;
  line-height: 17.5px;
  color: white;
}
